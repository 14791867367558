
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import MegaTable from "@/components/MegaTable.vue";
import { IBusinessSectorDB, IHeader, IMissionDB, IUserDB, LabelValue, MissionPeriod, StoreAction } from "@/types";
import { Action, Getter, State } from "vuex-class";
import { mixins } from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import Visualisations from "@/components/Visualisations.vue";
import moment from "moment-timezone";
import clonedeep from "lodash.clonedeep";
import MegaTableAts from "@/components/ats/MegaTableAts.vue";
import { ROUTES } from "@/data";

@Component({
    name: "Following",
    components: { MegaTableAts, Visualisations, MissionTile, MegaTable, ViewConfigurator },
})
export default class Following extends mixins(PageMixin) {
    loading = true;
    statusColumns: any = [];
    filteredMissionsByStatus: any = [];
    timeout: any = 0;
    viewFilters: any = this.$route.meta!.viewFilters.reduce((acc: any, filter: any) => {
        acc[filter.name] = filter.defaultValue;
        return acc;
    }, {});
    visualisations: any = {
        dateRange: {
            from: moment().startOf("week").valueOf(),
            to: moment().endOf("week").valueOf(),
        },
        mode: "week",
    };
    filters: any = { viewFilters: {} };
    pageTitle = this.$t("layout.left.followingMissions");

    @Action("actions/getMissionsByStatus") getMissionsByStatus!: StoreAction;
    @Action("actions/getArchivedMissions") getArchivedMissions!: StoreAction;

    @Getter("isAgency") isAgency!: boolean;

    @State("selectedCollaborators") selectedCollaborators!: IUserDB[];
    @State("selectedDomains") selectedDomains!: IBusinessSectorDB[];

    @Watch("selectedCollaborators", { deep: true })
    onCollabs() {
        this.timedSearch();
    }

    @Watch("selectedDomains", { deep: true })
    onDomains() {
        this.timedSearch();
    }

    @Watch("viewFilters", { deep: true })
    async onViewFilters(n: any, o: any) {
        await this.timedSearch();
    }

    @Watch("visualisations", { deep: true })
    onVisualisations() {
        this.timedSearch();
    }

    @Watch("isArchives")
    onArchive() {
        if (this.isArchives) {
            this.pageTitle = this.$t("page.following.archives.title");
        } else {
            this.pageTitle = this.$t("layout.left.globalFollowing");
        }
        this.timedSearch();
    }

    get headers(): IHeader[] {
        return (
            this.statusColumns?.map((column: any) => {
                return { label: this.$t("page.following.table.headers." + column.id) + ` (${column.missions?.length || 0})` };
            }) || []
        );
    }

    get rows() {
        return this.filteredMissionsByStatus;
    }

    get isArchives() {
        return this.$route.name === ROUTES.APP.FOLLOWING.ARCHIVE;
    }

    sortMissions(statusColumns: any[]): any[] {
        return (
            statusColumns?.map((missionsByStatus: any) => {
                return missionsByStatus.missions.sort((a: any, b: any) => {
                    const minA = this.periodMin(a.periods);
                    const minB = this.periodMin(b.periods);
                    const isFinishedMissions = missionsByStatus.id === "finishedMissions";
                    const asc = isFinishedMissions;
                    return asc ? minB - minA : minA - minB;
                });
            }) || []
        );
    }

    get formattedViewFilters() {
        if (this.isArchives) {
            if (this.isAgency) {
                return {
                    businessSector:
                        (this.viewFilters.businessSector?.length && this.viewFilters.businessSector.map((j: LabelValue) => j.value)) || undefined,
                    client: this.viewFilters.client || undefined,
                    jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
                };
            } else {
                return {
                    agency: this.viewFilters.agency || undefined,
                    jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
                };
            }
        } else {
            return {
                search: this.viewFilters.search || undefined,
                client: this.viewFilters.client || undefined,
                missionStatus:
                    (this.viewFilters.missionStatus?.length && this.viewFilters.missionStatus.map((j: LabelValue) => j.value).flat()) || undefined,
                businessSector:
                    (this.viewFilters.businessSector?.length && this.viewFilters.businessSector.map((j: LabelValue) => j.value)) || undefined,
                jobs: (this.viewFilters.jobs?.length && this.viewFilters.jobs.map((j: LabelValue) => j.value)) || undefined,
                missionAlert: this.viewFilters.missionAlert || undefined,
                contractType: this.viewFilters.contractType || undefined,
            };
        }
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === "number") {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        } else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate,
        };
    }

    get hasLocalFilters() {
        return !!this.selectedView?.filters?.missionAlert;
    }

    periodMin(periods: MissionPeriod[]) {
        let min = Infinity;

        if (periods && periods.length) {
            periods.forEach((period: MissionPeriod) => {
                if (period.start < min) {
                    min = period.start;
                }
            });
        }

        return min;
    }

    registerView(modelName: string) {
        this.$emit("registerView", {
            modelName,
            filters: {
                ...this.formattedViewFilters,
            },
        });
    }

    eraseView(selectedView: any) {
        this.$emit("eraseView", {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: {
                ...this.formattedViewFilters,
            },
        });
    }

    timedSearch() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    async init() {
        this.loading = true;
        try {
            this.filters.viewFilters = this.formattedViewFilters;
            this.statusColumns = await this.loadStatusColumns();


            this.filteredMissionsByStatus = this.sortMissions(clonedeep(this.statusColumns));
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async loadStatusColumns(): Promise<any[]> {
        return this.isArchives
            ? await this.getArchivedMissions({ ...this.ranges, ...this.filters.viewFilters })
            : await this.getMissionsByStatus(this.filters.viewFilters);
    }

    missionChanged(changedMission: IMissionDB) {
        this.timedSearch();
    }

    beforeMount() {
        this.timedSearch();
    }
}
